<template>
  <div>
    <h2 class="font-weight-bold">Sales Statistics</h2>
    <div class="p-3">
      <div class="row">
        <div
          class="
            col-12 col-sm-2
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <div
            class="
              main-numbers
              d-flex
              flex-row flex-sm-column
              justify-content-center
              align-items-center
            "
          >
            <div class="mr-2 mr-sm-0">{{ todayPayments }}</div>
            <div>{{ yesterdayPayments }}</div>
          </div>
        </div>
        <div class="col-12 col-sm-10">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <div class="mr-2">Last days</div>
            <div>
              <select v-model="days" class="form-control">
                <option value="7">7</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="180">180</option>
                <option value="360">360</option>
                <option value="720">720</option>
              </select>
            </div>
          </div>
          <v-chart
            ref="chart1"
            :option="countChartsOption"
            :auto-resize="true"
          ></v-chart>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <TotalRevenues></TotalRevenues>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TotalRevenues from "./TotalRevenues.vue";
import { EventBus } from "../event-bus.js";
import * as config from "../../config";
import { randomColorOnSeed } from "../utils/utils";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

const options = {
  responsive: true,
};

export default {
  name: "SalesStatistics",
  components: {
    VChart,
    TotalRevenues,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      days: 30,
      daySalesData: {},
      daySalesOptions: options,
      todayPayments: 0,
      yesterdayPayments: 0,
      countChartsOption: null,
    };
  },
  mounted() {
    this.loadDaySales();
    EventBus.$on("reload-daysales", () => {
      this.loadDaySales();
    });
  },
  watch: {
    days: function() {
      this.loadDaySales();
    },
  },
  methods: {
    loadDaySales() {
      const self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/statistics/sales/days?days=${self.days}`;
      self.$http
        .get(url)
        .then(function(response) {
          const rawData = response.data.reverse();
          const labels = rawData.map((d) => d.day);
          //   const payments = rawData.map((d) => d.payments);
          //   const amounts = rawData.map(d => d.amount);

          let colors = labels.map((l) => randomColorOnSeed(`${l}123456`));

          let paymentValues = [];
          for (let i = 0; i < rawData.length; i++) {
            paymentValues.push({
              value: rawData[i].payments,
              itemStyle: { color: colors[i] },
            });
          }

          let amountValues = [];
          for (let i = 0; i < rawData.length; i++) {
            amountValues.push({
              value: rawData[i].amount / 100,
              itemStyle: { color: colors[i] },
            });
          }

          self.todayPayments = paymentValues[labels.length - 1].value;
          self.yesterdayPayments = paymentValues[labels.length - 2].value;

          self.countChartsOption = {
            xAxis: [
              {
                type: "category",
                data: labels,
              },
            ],
            yAxis: [
              {
                type: "value",
                scale: true,
                name: "Payments",
              },
              {
                type: "value",
                scale: true,
                name: "Amount",
              },
            ],
            series: [
              {
                name: "Payments",
                data: paymentValues,
                type: "line",
                smooth: true,
                xAxisIndex: 0,
                yAxisIndex: 0,
                tooltip: {
                  trigger: "item",
                  formatter: "Payments<br/>{b}: {c} times",
                },
              },
              {
                name: "Amount",
                data: amountValues,
                type: "bar",
                smooth: true,
                xAxisIndex: 0,
                yAxisIndex: 1,
                tooltip: {
                  trigger: "item",
                  formatter: "Amount<br/>{b}: ${c}",
                },
              },
            ],
            tooltip: {
              trigger: "item",
            },
          };
        })
        .catch(function(error) {
          if (error.response) {
            self.$notify({
              group: "main",
              type: "error",
              text: error.response.data.message,
            });
          } else {
            self.$notify({ group: "main", type: "error", text: error });
          }
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.echarts {
  height: 50vh;
}

.main-numbers {
  :first-child {
    font-size: 100px;
    font-weight: bold;
  }

  :nth-child(2) {
    font-size: 30px;
    font-weight: bold;
  }
}
</style>
