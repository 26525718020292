<template>
  <div class="row">
    <div class="col-12">
      <h1 class="view-title">Crash Logs</h1>
    </div>
    <div class="col-12">
      <div class="main-field">
        <div class="left">
          <div class="devices-column">
            <table class="table table-striped">
              <thead>
                <th>Device</th>
              </thead>
              <tbody>
                <tr v-for="item in devices" :key="item.filename">
                  <td class="d-flex pointer">
                    <input
                      type="checkbox"
                      class="mr-2"
                      v-if="managing"
                      :value="item.filename"
                      v-model="selectedItems"
                      @change="filesSelected"
                    />
                    <div
                      @click="enterDir(item.filename)"
                      class="field-filename"
                    >
                      <b-icon icon="folder"></b-icon>
                      {{ item.filename }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="logs-column">
            <table class="table table-striped">
              <thead>
                <th>Log File</th>
              </thead>
              <tbody>
                <tr v-for="item in logs" :key="item.filename">
                  <td class="d-flex flex-column pointer">
                    <div>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-if="managing"
                        :value="item.filename"
                        v-model="selectedItems"
                        @change="filesSelected"
                      />
                      <div
                        class="field-filename"
                        @click="readLog(item.filename)"
                      >
                        <b-icon icon="file"></b-icon>
                        {{ item.filename }}
                      </div>
                    </div>

                    <div class="secondary-text">
                      {{ formatDate(item.last_modified) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="right">
          <div class="log-content-column">
            <pre v-if="currentLog">
              {{ formatLogContent(currentLog.content) }}
            </pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as config from "../../config";
import axios from "axios";
import * as utils from "../utils/utils";
import dayjs from "dayjs";

export default {
  name: "CrashLogs",
  components: {},
  data() {
    return {
      managing: false,
      devices: [],
      logs: [],
      currentLog: null,
      selectedDevice: null,
      selectedItems: [],
    };
  },
  created() {
    let self = this;
    self.listDevices();
  },
  mounted() {
    let self = this;

    self.$root.$on("bv::modal::show", () => {
      self.resetUploadForm();
    });
  },
  computed: {
    ...mapGetters({
      showLoading: "getShowLoading",
    }),
  },
  methods: {
    formatLogContent(content) {
      const cleanedString = content
        .trim()
        // .replace(/\\n/g, "")
        .replace(/\\"/g, '"')
        .replace(/\\\//g, "/");
      try {
        const jsonObj = JSON.parse(cleanedString);
        return JSON.stringify(jsonObj, null, 4);
      } catch (e) {
        console.error("Error parsing JSON:", e);
        return cleanedString;
      }
    },
    humanFileSize(size) {
      if (!size) return null;
      return utils.humanFileSize(size);
    },
    formatDate(date) {
      if (!date) return null;
      const d = dayjs.unix(date).format("YYYY-MM-DD HH:mm:ss");
      return d;
    },
    routeTo(to) {
      this.$router.push(to);
    },
    enterDir(dir) {
      this.selectedDevice = dir;
      this.listDevices();
      this.listLogs();
    },
    filesSelected() {
      let self = this;
      let selectedItems = self.selectedItems.map(
        (item) => `${self.path}/${item}`
      );
      this.$emit("filesSelected", selectedItems);
    },
    listDevices() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/crash_logs`;

      axios
        .get(url, {
          params: {
            path: "./",
          },
        })
        .then((resp) => {
          self.devices = resp.data;
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    listLogs() {
      let self = this;

      self.selectedItems = [];

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/crash_logs`;

      axios
        .get(url, {
          params: {
            path: "./" + self.selectedDevice,
          },
        })
        .then((resp) => {
          self.logs = resp.data;
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    readLog(item) {
      let self = this;

      self.selectedItems = [];

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/crash_logs`;

      axios
        .get(url, {
          params: {
            path: "./" + self.selectedDevice + "/" + item,
          },
        })
        .then((resp) => {
          self.currentLog = resp.data;
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    onFileSelectedChild(value) {
      console.log(value);
      this.selectedItems = value;
    },
    deleteItems() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/crash_logs`;

      console.log(">>>>>>>>>> deleteItems", self.selectedItems);

      axios
        .delete(url, {
          data: { devices: self.selectedItems },
        })
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          this.listDevices();
          this.listLogs();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-field {
  margin-top: 20px;
  display: flex;

  .left {
    width: 300px;
    display: flex;
    flex-direction: column;

    .devices-column {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      display: inline-block;
    }

    .logs-column {
      margin-top: 20px;
      width: 100%;
      max-height: 400px;
      overflow: auto;
      display: inline-block;
    }
  }

  .right {
    margin-left: 10px;
    width: calc(100% - 310px);

    .log-content-column {
      > pre {
        height: calc(100vh - 150px);

        padding: 10px;
        overflow: auto;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.secondary-text {
  color: #999999;
}

.pointer {
  :hover {
    cursor: pointer;
  }
}
</style>
