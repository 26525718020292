<template>
  <div>
    <h2 class="font-weight-bold">Using Statistics</h2>
    <div class="row">
      <div class="col-12">
        <form class="form-inline my-4">
          <div class="form-group">
            <label for="" class="sr-only">From Time</label>
            <b-form-datepicker
              id="datepicker-from-time"
              v-model="fromTime"
            ></b-form-datepicker>
          </div>
          <div class="form-group mx-sm-3">
            <label for="" class="sr-only">To Time</label>
            <b-form-datepicker
              id="datepicker-to-time"
              v-model="toTime"
            ></b-form-datepicker>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            @click.stop.prevent="loadUsingStats"
          >
            Query
          </button>
        </form>
      </div>
    </div>
    <div class="row" v-if="usingStats">
      <div class="col-12 d-flex flex-wrap charts">
        <div
          class="devices card d-flex flex-column justify-content-center align-items-center"
        >
          <div class="row w-100 py-2">
            <div
              class="col-6 d-flex flex-column justify-content-center align-items-center"
            >
              <div class="title">Total Devices</div>
              <div class="value">{{ usingStats.devices }}</div>
            </div>
            <div
              class="col-6 d-flex flex-column justify-content-center align-items-center"
            >
              <div class="title">Devices in An Hour</div>
              <div class="value">{{ usingStats.devices_in_an_hour }}</div>
            </div>
          </div>
          <div class="row w-100 py-2">
            <div
              class="col-6 d-flex flex-column justify-content-center align-items-center"
            >
              <div class="title">Played in Three Days</div>
              <div class="value">
                {{ usingStats.played_in_tree_days }}
              </div>
            </div>
            <div
              class="col-6 d-flex flex-column justify-content-center align-items-center"
            >
              <div class="title">Cheat Licensed</div>
              <div class="value">
                {{ usingStats.licensed.licensed_in_device_not_actually }}
              </div>
            </div>
          </div>
        </div>

        <div class="devices-each-day card">
          <v-chart
            class="chart"
            ref="chart-devices-each-day"
            :option="devicesEachDayChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="get-license-versions card">
          <v-chart
            class="chart"
            ref="chart-get-license-versions"
            :option="getLicenseVersionsChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="script-languages card">
          <v-chart
            class="chart"
            ref="chart-script-languages"
            :option="scriptLanguagesChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="rootless card">
          <v-chart
            class="chart"
            ref="chart-rootless"
            :option="rootlessChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="licensed-each-day card">
          <v-chart
            class="chart"
            ref="chart-licensed-each-day"
            :option="licensedEachDayChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="licensed-actually card">
          <v-chart
            class="chart"
            ref="chart-licensed-actually"
            :option="licensedActuallyChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="licensed-in-device card">
          <v-chart
            class="chart"
            ref="chart-licensed-in-device"
            :option="licensedInDeviceChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="ios-versions card">
          <v-chart
            class="chart"
            ref="chart-ios-versions"
            :option="iosVersionsChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="device-types card">
          <v-chart
            class="chart"
            ref="chart-device-types"
            :option="deviceTypesChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="device-models card">
          <v-chart
            class="chart"
            ref="chart-device-models"
            :option="deviceModelsChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="at-versions card">
          <v-chart
            class="chart"
            ref="chart-at-versions"
            :option="atVersionsChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="countries card">
          <v-chart
            class="chart"
            ref="chart-countries"
            :option="countriesChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="licensed-from-countries card">
          <v-chart
            class="chart"
            ref="chart-licensed-from-countries"
            :option="licensedFromCountriesChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="buyers card">
          <v-chart
            class="chart"
            ref="chart-buyers"
            :option="buyersChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="all-buyers card">
          <v-chart
            class="chart"
            ref="chart-all-buyers"
            :option="allBuyersChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>

        <div class="all-buying-from-countries card">
          <v-chart
            class="chart"
            ref="chart-all-all-buying-from-countries"
            :option="allBuyingFromCountriesChartOption"
            :auto-resize="true"
          ></v-chart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as config from "../../config";
import { getDaysAgo } from "../utils/utils";
import { randomColorOnSeed } from "../utils/utils";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  PolarComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  PieChart,
  PolarComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  name: "UsingStatistics",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      days: 7,
      fromTime: getDaysAgo(7)
        .toISOString()
        .substring(0, 10),
      toTime: new Date().toISOString().substring(0, 10),
      usingStats: [],
      playingStatistics: [],
      getLicenseVersions: [],
    };
  },
  mounted() {
    const self = this;

    self.loadGetLicenseVersionsStatistics();
    self.loadPlayingStatistics();
    self.loadUsingStats();
    // setInterval(function() {
    //   self.loadUsingStats();
    // }, 15000);
  },
  watch: {
    days: function() {
      this.loadUsingStats();
    },
  },
  methods: {
    loadUsingStats() {
      const self = this;

      const url = `${config.server}/api/v1/statistics/using`;
      self.$http
        .get(url, {
          params: {
            from_time: `${self.fromTime}T00:00:00.000Z`,
            to_time: `${self.toTime}T23:59:59.999Z`,
          },
        })
        .then(function(response) {
          self.usingStats = response.data;
        })
        .catch(function(error) {
          if (error.response) {
            self.$notify({
              group: "main",
              type: "error",
              text: error.response.data.message,
            });
          } else {
            self.$notify({ group: "main", type: "error", text: error });
          }
        })
        .finally(() => {});
    },
    loadPlayingStatistics() {
      const self = this;

      const url = `${config.server}/api/v1/statistics/playing`;
      self.$http
        .get(url)
        .then(function(response) {
          self.playingStatistics = response.data;
        })
        .catch(function(error) {
          if (error.response) {
            self.$notify({
              group: "main",
              type: "error",
              text: error.response.data.message,
            });
          } else {
            self.$notify({ group: "main", type: "error", text: error });
          }
        })
        .finally(() => {});
    },
    loadGetLicenseVersionsStatistics() {
      const self = this;

      const url = `${config.server}/api/v1/statistics/get_license_versions`;
      self.$http
        .get(url)
        .then(function(response) {
          self.getLicenseVersions = response.data;
        })
        .catch(function(error) {
          if (error.response) {
            self.$notify({
              group: "main",
              type: "error",
              text: error.response.data.message,
            });
          } else {
            self.$notify({ group: "main", type: "error", text: error });
          }
        })
        .finally(() => {});
    },
  },
  computed: {
    scriptLanguagesChartOption() {
      const data = this.playingStatistics;
      return {
        title: {
          text: "Script Languages",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: data.lua_file, name: "Lua File" },
              { value: data.js_file, name: "Js File" },
              { value: data.lua_package, name: "Lua Package" },
              { value: data.js_package, name: "Js Package" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    rootlessChartOption() {
      const data = this.usingStats.rootless;
      return {
        title: {
          text: "Rootless VS Rootful",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: data.rootless, name: "Rootless" },
              { value: data.rootful, name: "Rootful" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    devicesEachDayChartOption() {
      const data = this.usingStats.devices_each_day;
      return {
        title: {
          text: "Devices Of Each Day",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: data.map((d) => d.date),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data.map((d) => d.count),
            type: "line",
            areaStyle: {},
          },
        ],
      };
    },
    iosVersionsChartOption() {
      const data = this.usingStats.ios_versions.map((item) => {
        return {
          value: item.count,
          name: `iOS ${item.ios_version}`,
        };
      });

      return {
        title: {
          text: "iOS Versions",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    atVersionsChartOption() {
      return {
        title: {
          text: "AutoTouch Versions",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        grid: { containLabel: true },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: this.usingStats.versions.map((item) => item.version).reverse(),
        },
        series: [
          {
            data: this.usingStats.versions.map((item) => item.count).reverse(),
            type: "bar",
          },
        ],
      };
    },
    countriesChartOption() {
      let colors = this.usingStats.countries.map((item) =>
        randomColorOnSeed(`${item.country}123456`)
      );

      let values = [];
      let countries = this.usingStats.countries;
      for (let i = 0; i < countries.length; i++) {
        values.push({
          value: countries[i].count,
          itemStyle: { color: colors[i] },
        });
      }

      return {
        title: {
          text: "Devices From Countries",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: this.usingStats.countries.map((item) => item.country),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: values,
            type: "bar",
          },
        ],
      };
    },
    licensedFromCountriesChartOption() {
      let colors = this.usingStats.countries.map((item) =>
        randomColorOnSeed(`${item.country}123456`)
      );

      let values = [];
      let countries = this.usingStats.licensed_from_countries;
      for (let i = 0; i < countries.length; i++) {
        values.push({
          value: countries[i].count,
          itemStyle: { color: colors[i] },
        });
      }

      return {
        title: {
          text: "Licensed From Countries",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: this.usingStats.licensed_from_countries.map(
            (item) => item.country
          ),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: values,
            type: "bar",
          },
        ],
      };
    },
    licensedActuallyChartOption() {
      return {
        title: {
          text: "Licensed Actually",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              {
                name: "Total devices",
                value: this.usingStats.devices,
              },
              {
                name: "Licensed actually",
                value: this.usingStats.licensed.licensed_actually,
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    licensedInDeviceChartOption() {
      return {
        title: {
          text: "Licensed In Device",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              {
                name: "Total devices",
                value: this.usingStats.devices,
              },
              {
                name: "Licensed in device",
                value: this.usingStats.licensed.licensed_in_device,
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    licensedEachDayChartOption() {
      const data = this.usingStats.licensed_each_day;
      return {
        title: {
          text: "Licensed Devices Of Each Day",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: data.map((d) => d.date),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data.map((d) => d.count),
            type: "line",
            areaStyle: {},
          },
        ],
      };
    },
    deviceTypesChartOption() {
      const data = this.usingStats.device_types.map((item) => {
        return {
          value: item.count,
          name: item.device_type,
        };
      });

      return {
        title: {
          text: "Device Types",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    deviceModelsChartOption() {
      return {
        title: {
          text: "Device Models",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        grid: { containLabel: true },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: this.usingStats.device_models.map((item) => item.device_mode),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.usingStats.device_models.map((item) => item.count),
            type: "bar",
          },
        ],
      };
    },
    buyersChartOption() {
      let colors = this.usingStats.buyers.map((item) =>
        randomColorOnSeed(`${item.payer_email}123456`)
      );

      let values = [];
      let buyers = this.usingStats.buyers;
      for (let i = 0; i < buyers.length; i++) {
        values.push({
          itemStyle: { color: colors[i] },
          value: (buyers[i].total_amount / 100).toFixed(2),
          license_count: buyers[i].license_count,
          payer_email: buyers[i].payer_email,
          payer_country: buyers[i].payer_country,
        });
      }

      return {
        title: {
          text: "Buyers",
          subtext: `${this.fromTime} ~ ${this.toTime}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: function(item) {
            return `Total amount: ${item.data.value}<br />
            License count: ${item.data.license_count}<br />
            Email: ${item.data.payer_email}<br />
            Country: ${item.data.payer_country}`;
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: buyers.map((item) => item.payer_email),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: values,
            type: "bar",
          },
        ],
      };
    },
    allBuyersChartOption() {
      let colors = this.usingStats.all_buyers.map((item) =>
        randomColorOnSeed(`${item.payer_email}123456`)
      );

      let values = [];
      let all_buyers = this.usingStats.all_buyers;
      for (let i = 0; i < all_buyers.length; i++) {
        values.push({
          itemStyle: { color: colors[i] },
          value: (all_buyers[i].total_amount / 100).toFixed(2),
          license_count: all_buyers[i].license_count,
          payer_email: all_buyers[i].payer_email,
          payer_country: all_buyers[i].payer_country,
        });
      }

      return {
        title: {
          text: "All Buyers",
          subtext: `All time`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: function(item) {
            return `Total amount: ${item.data.value}<br />
            License count: ${item.data.license_count}<br />
            Email: ${item.data.payer_email}<br />
            Country: ${item.data.payer_country}`;
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: all_buyers.map((item) => item.payer_email),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: values,
            type: "bar",
          },
        ],
      };
    },
    allBuyingFromCountriesChartOption() {
      let colors = this.usingStats.all_buying_from_countries.map((item) =>
        randomColorOnSeed(`${item.country}123456`)
      );

      let values = [];
      let all_buying_from_countries = this.usingStats.all_buying_from_countries;
      for (let i = 0; i < all_buying_from_countries.length; i++) {
        values.push({
          itemStyle: { color: colors[i] },
          value: (all_buying_from_countries[i].total_amount / 100).toFixed(2),
          license_count: all_buying_from_countries[i].license_count,
          country: all_buying_from_countries[i].country,
        });
      }

      return {
        title: {
          text: "All Buying From Countries",
          subtext: `All time`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: function(item) {
            return `Country: ${item.data.country}<br />
            Total amount: ${item.data.value}<br />
            License count: ${item.data.license_count}<br />`;
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
          data: all_buying_from_countries.map((item) => item.country),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: values,
            type: "bar",
          },
        ],
      };
    },
    getLicenseVersionsChartOption() {
      let self = this;

      // const uniqueVersions = new Set(
      //   self.getLicenseVersions.map((item) => `v${item.version}`)
      // );
      // const uniqueDates = new Set(
      //   self.getLicenseVersions.map((item) => item.date)
      // );
      // const sortedUniqueDates = Array.from(uniqueDates).sort((a, b) => a < b);

      // Extract unique dates and sort them
      const dates = [
        ...new Set(self.getLicenseVersions.map((item) => item.date)),
      ].sort((a, b) => a - b);

      // Group data by version
      const versionData = self.getLicenseVersions.reduce((acc, cur) => {
        acc[cur.version] = acc[cur.version] || [];
        acc[cur.version].push({
          date: cur.date,
          device_count: cur.device_count,
        });
        return acc;
      }, {});

      // Prepare series data for each version, aligning device counts with dates
      const series = Object.keys(versionData).map((version) => ({
        name: `Version ${version}`,
        type: "line",
        stack: "total",
        data: dates.map((date) => {
          const entry = versionData[version].find((item) => item.date === date);
          return entry ? entry.device_count : 0;
        }),
      }));

      return {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: series.map((s) => s.name),
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dates.map((date) =>`${date}`.slice(0, 4) + '-' + `${date}`.slice(4, 6) + '-' + `${date}`.slice(6, 8)
          ), // Convert timestamps to readable dates
        },
        yAxis: {
          type: "value",
        },
        series: series,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.charts {
  margin: -10px !important;

  .card {
    display: inline-block;
    background-color: #ffffff;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #ced4da;
    border-radius: 2px;
    padding: 20px;
    margin: 10px;
  }

  .devices {
    width: 441px;
    height: 441px;

    .title {
    }

    .value {
      font-size: 80px;
      font-weight: bold;
    }
  }

  .devices-each-day,
  .licensed-each-day,
  .get-license-versions {
    .chart {
      width: 1200px;
      height: 400px;
    }
  }

  .at-versions,
  .device-models,
  .countries,
  .licensed-from-countries,
  .buyers,
  .all-buyers,
  .all-buying-from-countries {
    .chart {
      width: 800px;
      height: 400px;
    }
  }

  .rootless,
  .ios-versions,
  .licensed-actually,
  .licensed-in-device,
  .device-types,
  .script-languages {
    .chart {
      width: 400px;
      height: 400px;
    }
  }
}
</style>
