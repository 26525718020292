import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Notifications from 'vue-notification'
import VueRouter from "vue-router";
import Vuex from 'vuex'
import VeeValidate from 'vee-validate';
import { store } from './store/index';
import { authService } from './service/auth';
import { router } from './router'
import './version';
import * as config from "../config";

import * as axios from 'axios';
import './style.scss'

var VueTruncate = require('vue-truncate-filter')

Vue.use(VueTruncate)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Notifications)
Vue.use(Vuex)
Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        // valid: 'is-valid',
        invalid: 'is-invalid'
    }
});

const server = require("../config").server;
axios.defaults.baseURL = server;

// http request 拦截器
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalRequest = error.config;

        if (error.response.status === 401) {
            if (originalRequest.url.includes("/api/v1/auth/refresh_token")) {
                authService.logout();
                return Promise.reject(error);
            } else if (!originalRequest._retry) {
                originalRequest._retry = true;
                await refresh_token();
                return axios(originalRequest);
            }
        } else if (error.response.status === 400 || error.response.status === 403) {
            Vue.notify({
                group: "main",
                type: "error",
                text: error.response.data
            });
        }

        return Promise.reject(error);
    }
);

async function refresh_token() {
    let refreshToken = localStorage.getItem("refreshToken");

    let resp = await axios({
        url: `${config.server}/api/v1/auth/refresh_token`,
        method: "post",
        data: {
            refresh_token: refreshToken,
        },
    });

    if (resp.status == 200) {
        authService.setRefreshedTokens(resp.data.access_token, resp.data.refresh_token);
    } else {
        Vue.notify({
            group: 'main',
            type: 'error',
            text: 'Failed to refresh token!'
        });
    }
}

// 路由防卫
router.beforeEach((to, from, next) => {
    // 权限
    let titleArr = ["Licenses", "Statistics"];
    if (titleArr.indexOf(to.meta.title) > -1) {
        if (store.state.project.projectName == null) {
            document.title = to.meta.title;
        } else {
            document.title = store.state.project.projectName + "­­­­­­­－" + to.meta.title;
        }
    } else {
        if (to.meta.title) {
            document.title = to.meta.title;
        } else {
            document.title = "AutoTouch";
        }
    }
    if (to.meta.requireAuth) {
        let isLogin = localStorage.getItem("accessToken");
        if (isLogin || titleArr.indexOf(to.name) != "-1") {
            next();
        } else {
            next();
            // store.commit('logout');
            // next({
            //   path: '/login',
            //   query: {
            //     redirect: to.fullPath
            //   }
            // })
        }
    } else {
        next();
    }
});

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')