<template>
  <div class="row d-flex flex-row justify-content-center">
    <div
      class="
        col-12 col-sm-2
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
    >
      <img src="../assets/logo.png" alt="" class="logo my-4" />
      <div class="title my-4">AutoTouch</div>
      <div class="d-flex flex-column w-100">
        <button
          class="login-button login-button-google my-2"
          @click.stop.prevent="onLogin('google')"
        >
          <img src="../assets/google-logo.png" alt="" />
          <span>Login with Google</span>
        </button>

        <!-- <button
          class="login-button login-button-github my-2"
          @click.stop.prevent="onLogin('github')"
        >
          <img src="../assets/github-logo.png" alt="" />
          <span>Login with GitHub</span>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as config from "../../config";
import axios from "axios";
import { randomString } from "../utils/utils";
import jwt from "jsonwebtoken";
import { authService } from "../service";

export default {
  name: "Register",
  data() {
    return {
      isCallback: false,
      provider: this.$route.params.provider,
    };
  },
  beforeMount() {
    if (
      this.provider &&
      this.$route.query &&
      this.$route.query.code &&
      this.$route.query.state
    ) {
      this.isCallback = true;
      this.exchangeToken(this.$route.query.code, this.$route.query.state);
    }
  },
  methods: {
    onLogin(provider) {
      let session_id = randomString(16);
      localStorage.setItem("session_id", session_id);

      window.location.href = `${config.server}/api/v1/auth/oauth/login/${provider}?session_id=${session_id}`;
    },
    exchangeToken(code, state) {
      const self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/auth/oauth/callback/${self.provider}`;

      const session_id = localStorage.getItem("session_id");

      axios
        .get(url, {
          params: {
            code,
            state,
            session_id,
          },
        })
        .then(function (res) {
          console.log(res);
          if (res.data) {
            let decoded_jwt = jwt.decode(res.data.access_token);
            console.log(decoded_jwt);

            if (decoded_jwt) {
              authService.login(
                res.data.access_token,
                res.data.refresh_token,
                decoded_jwt.user
              );

              const redirect = self.$route.query.redirect;
              self.$router.push({ path: redirect || "/" });
            }
          } else {
            self.$notify({
              group: "main",
              type: "error",
              text: "Invalid access token!",
            });
            return;
          }

          const redirect = self.$route.query.redirect;
          self.$router.push({ path: redirect || "/" });

          self.$notify({
            group: "main",
            type: "success",
            text: "Logged in!",
          });
        })
        .catch(function (error) {
          if (error.response) {
            self.$notify({
              group: "main",
              type: "error",
              text: error.response.data.message,
            });
          } else {
            self.$notify({ group: "main", type: "error", text: error });
          }
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
          //   localStorage.removeItem("session_id");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 36px;
  font-weight: bold;
}

.login-button {
  width: 100%;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 3px;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    padding-right: 10px;
  }

  span {
    font-size: 16px;
  }
}

.login-button-google {
  background-color: #ffffff;
  border: #6b6b6b;
  color: #333333;

  img {
    height: 32px;
  }
}

.login-button-github {
  background-color: #24292f;
  border: #17191d;
  color: #ffffff;

  img {
    height: 32px;
  }
}

@media (min-width: 992px) {
  .login {
    padding: 30px 30px;
  }
}

.footer {
  text-align: center;
}
</style>
