<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="info"
    class="navbar navbar-expand-sm navbar-dark bg-dark"
  >
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">AutoTouch</router-link>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="isLoggedIn">
          <li
            class="nav-item"
            v-bind:class="{
              active: $route.path === '/' || $route.path === '/scripts',
            }"
          >
            <router-link class="nav-link" to="/">Licenses</router-link>
          </li>
          <li
            class="nav-item"
            v-bind:class="{
              active: $route.path === '/orders',
            }"
          >
            <router-link class="nav-link" to="/orders">Orders</router-link>
          </li>
          <li
            class="nav-item"
            v-bind:class="{
              active: $route.path === '/statistics/using',
            }"
          >
            <router-link class="nav-link" to="/statistics/using"
              >Statistics</router-link
            >
          </li>
          <li
            class="nav-item"
            v-bind:class="{
              active: $route.path === '/statistics/sales',
            }"
          >
            <router-link class="nav-link" to="/statistics/sales"
              >Sales</router-link
            >
          </li>

          <template v-if="isSuperAdmin">
            <li
              class="nav-item"
              v-bind:class="{ active: $route.path === '/createLicense' }"
            >
              <a
                href="#"
                class="nav-link"
                @click.prevent.stop="$bvModal.show('modal-create-licenses')"
                >Create Licenses</a
              >
            </li>

            <li
              class="nav-item"
              v-bind:class="{
                active: $route.path === '/users',
              }"
            >
              <router-link class="nav-link" to="/users">Users</router-link>
            </li>

            <li
              class="nav-item"
              v-bind:class="{
                active: $route.path === '/repo',
              }"
            >
              <router-link class="nav-link" to="/repo">Repo</router-link>
            </li>

            <li
              class="nav-item"
              v-bind:class="{
                active: $route.path === '/crashLogs',
              }"
            >
              <router-link class="nav-link" to="/crashLogs"
                >Crash Logs</router-link
              >
            </li>

            <li
              class="nav-item"
              v-bind:class="{
                active: $route.path === '/globalSettings',
              }"
            >
              <router-link class="nav-link" to="/globalSettings"
                >Global Settings</router-link
              >
            </li>
          </template>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <!-- Check that the SDK client is not currently loading before accessing is methods -->
          <!-- show login when not authenticated -->
          <li class="nav-item" v-if="!isLoggedIn">
            <router-link class="nav-link" to="/login">Login</router-link>
          </li>

          <!-- show logout when authenticated -->
          <template v-else>
            <b-nav-item-dropdown right>
              <template #button-content>
                <img :src="user.avatar_url" alt="avatar" class="avatar" />
                <em>{{ user.name }}</em>
              </template>
              <b-dropdown-item @click="logout">Logout</b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </div>

    <b-modal id="modal-create-licenses" title="Createa licenses" hide-footer>
      <form action method="post" enctype="multipart/form-data">
        <div class="form-group">
          <label>Plan:</label>
          <select class="form-control" name="plan" v-model="plan">
            <option value="IOS_BASIC">iOS Basic</option>
            <option value="IOS_PRO">iOS Pro</option>
            <option value="MACOS_BASIC">macOS Basic</option>
            <option value="MACOS_PRO">macOS Pro</option>
          </select>
        </div>
        <div class="form-group">
          <label>Validity:</label>
          <select class="form-control" name="validity" v-model="validity">
            <option value="1">1 Monthes</option>
            <option value="3">3 Monthes</option>
            <option value="6">6 Monthes</option>
            <option value="12">12 Monthes</option>
            <option value="24">24 Monthes</option>
            <option value="36">36 Monthes</option>
          </select>
        </div>
        <div class="form-group">
          <label>Count:</label>
          <input
            type="number"
            class="form-control"
            name="count"
            step="1"
            min="0"
            max="10000"
            required
            v-model="count"
            v-validate="'required|numeric|min_value:1'"
          />
          <div class="invalid-feedback">{{ errors.first("count") }}</div>
        </div>
        <div class="form-group">
          <label>Payee Email:</label>
          <b-form-select
            class="form-control"
            v-model="payeeEmail"
            :options="payeeEmails"
            required
            v-validate="'required'"
          ></b-form-select>
          <div class="invalid-feedback">{{ errors.first("payeeEmail") }}</div>
        </div>
        <div class="form-group">
          <label>Bind to email:</label>
          <input
            type="email"
            class="form-control"
            name="email"
            v-model="email"
          />
          <div class="invalid-feedback">{{ errors.first("email") }}</div>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          v-on:click.stop.prevent="createLicenses()"
        >
          Submit
        </button>
      </form>
    </b-modal>
  </b-navbar>
</template>

<script>
import * as config from "../../config";
import axios from "axios";
import { mapGetters } from "vuex";
import { EventBus } from "../event-bus.js";
import { authService } from "../service/index";

export default {
  name: "Navbar",
  data() {
    return {
      count: 1,
      validity: 12,
      plan: "IOS_BASIC",
      email: null,
      payeeEmail: null,
      payeeEmails: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoggedIn: "isLoggedIn",
    }),
    isSuperAdmin() {
      return (this.user.roles & 0b10000000) > 0;
    },
  },
  created() {
    this.loadGlobalSettings();
  },
  methods: {
    // Log the user out
    logout() {
      authService.logout({
        group: "main",
        type: "success",
        text: "Logged out!",
      });
    },
    resetCreateLicensesData() {
      this.validity = 12;
      this.count = 1;
      this.email = null;
    },
    loadGlobalSettings() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/global_settings`;

      axios
        .get(url, {})
        .then((resp) => {
          const { paypal_payee_email_1, paypal_payee_email_2 } = resp.data;
          self.payeeEmails = [
            {
              value: null,
              text: `Null`,
            },
            {
              value: paypal_payee_email_1,
              text: `Payee Paypal Email 1 -- ${paypal_payee_email_1}`,
            },
            {
              value: paypal_payee_email_2,
              text: `Payee Paypal Email 2 -- ${paypal_payee_email_2}`,
            },
          ];
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });

      return;
    },
    createLicenses() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses`;

      axios
        .post(url, {
          validity: +this.validity,
          quantity: +this.count,
          plan: this.plan,
          payee_email: this.payeeEmail,
          payer_email: this.email,
        })
        .then(() => {
          self.resetCreateLicensesData();
          self.$bvModal.hide("modal-create-licenses");
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          EventBus.$emit("licenses-created", this.clickCount);
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid white;
  margin-right: 5px;
}
</style>
