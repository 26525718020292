<template>
  <div class="row">
    <div class="col-12">
      <h1 class="view-title">Repo</h1>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-start">
        <button
          class="btn btn-sm btn-secondary ml-2"
          @click="$bvModal.show('modal-upload-package')"
        >
          Upload Package
        </button>
        <button class="btn btn-sm btn-secondary ml-2" @click="refreshRepo">
          Refresh Release
        </button>
        <button
          class="btn btn-sm btn-secondary ml-2"
          @click="
            managing = !managing;
            selectedItems = [];
          "
        >
          Manage
        </button>
        <button
          class="btn btn-sm btn-secondary ml-2"
          v-if="managing"
          @click="deleteItems()"
        >
          Delete
        </button>
      </div>
    </div>
    <div class="col-12">
      <FileList
        :managing="managing"
        @filesSelected="onFileSelectedChild"
      />
    </div>

    <b-modal id="modal-upload-package" title="Upload Package">
      <form action method="post" enctype="multipart/form-data">
        <div class="form-group d-flex flex-row align-items-center">
          <input
            class=""
            type="checkbox"
            v-model="uploadForm.refresh"
            value=""
            id="refresh"
          />
          <label class="form-check-label ml-2" for="refresh">
            Refresh release files
          </label>
        </div>
        <div class="form-group">
          <label>Package Files:</label>
          <div class="my-1">
            <input type="file" @change="fileSelected" ref="files" multiple />
          </div>
        </div>
      </form>

      <template #modal-footer="{ cancel, hide }">
        <b-button variant="light" @click="cancel()">
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="
            hide('upload');
            upload();
          "
        >
          Submit
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FileList from "../components/FileList.vue";
import { mapGetters } from "vuex";
import * as config from "../../config";
import axios from "axios";
import { EventBus } from "../event-bus.js";

export default {
  name: "RepoFiles",
  components: {
    FileList,
  },
  data() {
    return {
      managing: false,
      selectedItems: [],
      uploadForm: {
        refresh: true,
        file: null,
      },
    };
  },
  created() {},
  mounted() {
    let self = this;

    self.$root.$on("bv::modal::show", () => {
      self.resetUploadForm();
    });
  },
  computed: {
    ...mapGetters({
      showLoading: "getShowLoading",
    }),
  },
  methods: {
    resetUploadForm() {
      this.uploadForm = {
        refresh: true,
        files: null,
      };
    },
    fileSelected() {
      this.uploadForm.files = this.$refs.files.files;
    },
    upload() {
      let self = this;

      const formData = new FormData();
      for (let i = 0; i < self.uploadForm.files.length; i++) {
        const file = self.uploadForm.files[i];
        formData.append(`file`, file);
      }

      formData.append("refresh", self.uploadForm.refresh);
      const headers = { "Content-Type": "multipart/form-data" };

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/repo/packages`;

      axios
        .post(url, formData, { headers })
        .then(() => {
          self.$bvModal.hide("modal-upload-package");
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          EventBus.$emit("reload-repo-files", this.clickCount);
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    refreshRepo() {
      if (!window.confirm(`Are you sure to refresh the repo?`)) {
        return;
      }

      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/repo/refresh`;

      axios
        .put(url)
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          EventBus.$emit("reload-repo-files", this.clickCount);
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    onFileSelectedChild(value) {
      console.log(value);
      this.selectedItems = value;
    },
    deleteItems() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/repo/packages`;

      console.log(">>>>>>>>>> deleteItems", self.selectedItems);

      axios
        .delete(url, {
          data: { files: self.selectedItems },
        })
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          EventBus.$emit("reload-repo-files", this.clickCount);
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
