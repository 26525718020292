<template>
  <div>
    <h2>Revenues Query</h2>
    <form class="form-inline my-4">
      <div class="form-group">
        <label for="" class="sr-only">From Time</label>
        <b-form-datepicker
          id="datepicker-from-time"
          v-model="fromTime"
        ></b-form-datepicker>
      </div>
      <div class="form-group mx-sm-3">
        <label for="" class="sr-only">To Time</label>
        <b-form-datepicker
          id="datepicker-to-time"
          v-model="toTime"
        ></b-form-datepicker>
      </div>
      <button type="submit" class="btn btn-primary" @click.stop.prevent="query">
        Query
      </button>
    </form>
    <table class="table table-bordered">
      <tr>
        <th>Payee Email</th>
        <th>Total Revenue</th>
        <th>Total PayPal Fee</th>
        <th>Total Received</th>
        <th>Payment Count</th>
        <th>Amount / Payment</th>
      </tr>
      <tr v-for="item in totalRevenues" :key="item.payee_email">
        <td>{{ item.payee_email }}</td>
        <td>${{ Math.round(item.total_revenue / 100).toFixed(2) }}</td>
        <td>${{ Math.round(item.paypal_fee / 100).toFixed(2) }}</td>
        <td>
          ${{
            Math.round((item.total_revenue - item.paypal_fee) / 100).toFixed(2)
          }}
        </td>
        <td>{{ item.payments }}</td>
        <td>
          ${{ Math.round(item.total_revenue / 100 / item.payments).toFixed(2) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as config from "../../config";
import axios from "axios";
import { getMonthAgo } from "../utils/utils";

export default {
  name: "TotalRevenues",
  data() {
    return {
      fromTime: getMonthAgo().toISOString().substring(0, 10),
      toTime: new Date().toISOString().substring(0, 10),
      totalRevenues: [],
    };
  },
  methods: {
    query() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/statistics/total_revenues`;

      axios
        .get(url, {
          params: {
            from_time: `${self.fromTime}T00:00:00.000Z`,
            to_time: `${self.toTime}T23:59:59.999Z`,
          },
        })
        .then((resp) => {
          const { data } = resp;
          self.totalRevenues = data;
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
</style>