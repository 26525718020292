<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="toolbar">
          <div class="row">
            <div
              class="
                col-12 col-md-3
                my-2 my-sm-0
                d-flex
                justify-content-start
                align-items-center
              "
            >
              <h4 class="font-weight-bold my-0">
                Today Sales:
                {{ todaySales }}
              </h4>
              <div class="ml-4">Total Items: {{ total }}</div>
            </div>

            <div
              class="
                col-12 col-md-3
                my-2 my-sm-0
                d-flex
                justify-content-start
                align-items-center
              "
            >
              <button
                class="btn btn-secondary btn-sm"
                @click="select = !select"
              >
                Select
              </button>
              <template v-if="select">
                <div
                  class="d-flex
                justify-content-start
                align-items-center ml-2"
                >
                  <input type="checkbox" @change="selectAll" />
                  <div class="ml-1">Select All</div>
                </div>
                <button class="btn btn-primary btn-sm ml-2" @click="renew">
                  Renew
                </button>
              </template>
            </div>

            <div
              class="
                col-12 col-md-3
                my-2 my-sm-0
                d-flex
                justify-content-start justify-content-sm-center
                align-items-center
              "
            >
              <template isSuperAdmin>
                <textarea
                  type="text"
                  v-model="searchText"
                  rows="1"
                  placeholder="License key or PayPal email or activated device"
                  class="form-control form-control-sm w-100"
                />
                <div>
                  <button
                    class="form-control form-control-sm ml-2"
                    v-on:click.stop.prevent="searchLicenses"
                  >
                    Search
                  </button>
                </div>
              </template>
            </div>
            <div
              class="
                col-12 col-md-3
                my-2 my-sm-0
                d-flex
                justify-content-between justify-content-sm-end
                align-items-center
              "
            >
              <div>
                <button
                  class="btn btn-secondary btn-sm ml-auto mr-2"
                  v-on:click.stop.prevent="refresh"
                >
                  Refresh
                </button>
              </div>
              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                size="sm"
                class="float-xs-left float-sm-right my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-12 col-sm-auto py-2 py-md-3 justify-content-between"
        v-for="item in items"
        v-bind:key="item.license.id"
      >
        <div
          class="card license-card"
          :class="{
            'card-today':
              item.paypal_payment &&
              isTodaySale(item.paypal_payment.create_time),
          }"
        >
          <div class="card-body">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between align-items-center"
              >
                <input
                  v-if="select"
                  type="checkbox"
                  name="selectedLicenses"
                  v-model="selectedLicenses"
                  :value="item.license.license_key"
                />
                <div class>{{ item.license.validity }} Months</div>
                <svg
                  v-if="
                    item.paypal_payment &&
                      item.paypal_payment.from_method === 'webhook'
                  "
                  class="webhook-flag"
                />
                <div
                  :class="{
                    'basic-flag':
                      item.license.plan == 'IOS_BASIC' ||
                      item.license.plan == 'MACOS_BASIC',
                    'pro-flag':
                      item.license.plan == 'IOS_PRO' ||
                      item.license.plan == 'MACOS_PRO',
                  }"
                >
                  {{ item.license.plan }}
                </div>
                <div>
                  <img
                    v-if="item.license.created_manually"
                    src="../assets/hand.png"
                    class="hand-flag"
                  />
                </div>
                <div>
                  <div
                    class="badge badge-pill"
                    :id="'popover-' + item.license.id"
                    v-bind:class="{
                      'badge-success': item.license.status == 'NORMAL',
                      'badge-danger': item.license.status == 'REFUNDED',
                      'badge-warning': item.license.status == 'REVOKED',
                    }"
                  >
                    {{ item.license.status }}
                  </div>

                  <b-popover
                    :target="'popover-' + item.license.id"
                    triggers="hover click"
                    placement="bottom"
                  >
                    <div>
                      <div>
                        PayPal Order Id:
                        <span v-if="item.paypal_payment">{{
                          item.paypal_payment.paypal_order_id
                        }}</span>
                      </div>
                      <div>
                        PayPal Capture Id:
                        <span v-if="item.paypal_payment">{{
                          item.paypal_payment.paypal_capture_id
                        }}</span>
                      </div>
                      <div>
                        PayPal Refund Id:
                        <span v-if="item.paypal_payment">{{
                          item.paypal_payment.paypal_refund_id
                        }}</span>
                      </div>
                      <div>
                        PayPal Refund Time:
                        <span v-if="item.paypal_payment">{{
                          formatDate(item.paypal_payment.refund_time)
                        }}</span>
                      </div>
                    </div>
                  </b-popover>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="license-key">{{ item.license.license_key }}</div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-10 d-flex justify-content-start align-items-center"
              >
                Expires:
                <span v-if="item.license.expire_time">{{
                  formatDate(item.license.expire_time)
                }}</span>
              </div>
              <div class="col-2 d-flex justify-content-end align-items-center">
                <b-dropdown variant="button" no-caret right isSuperAdmin>
                  <template slot="button-content">
                    <b-icon icon="gear"></b-icon>
                  </template>

                  <template v-if="isLoggedIn">
                    <!-- v-if="isActivationLocked(item.license.activation_lock_to)" -->
                    <template>
                      <b-dropdown-item
                        v-on:click.stop.prevent="
                          unlockActivation(item.license.id)
                        "
                        >Unlock Activation</b-dropdown-item
                      >
                      <b-dropdown-divider></b-dropdown-divider>
                    </template>
                    <template v-if="!item.license.user_email">
                      <b-dropdown-item
                        v-on:click.stop.prevent="
                          attachLicenseToEmail(item.license.id)
                        "
                        >Attach To Email</b-dropdown-item
                      >
                      <b-dropdown-divider></b-dropdown-divider>
                    </template>
                    <b-dropdown-item
                      v-on:click.stop.prevent="
                        setPayeeEmailLicenseId = item.license.id;
                        $bvModal.show('modal-set-payee-email');
                      "
                      >Set Payee Email</b-dropdown-item
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item
                      v-on:click.stop.prevent="refund(item.license.id)"
                      >Refund</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-on:click.stop.prevent="revoke(item.license.id)"
                      >Revoke</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-on:click.stop.prevent="deletee(item.license.id)"
                      >Delete</b-dropdown-item
                    >
                  </template>
                </b-dropdown>
              </div>
            </div>
            <div class="border p-2 my-2">
              <div>
                Activated Device:
                <span v-if="item.activation">{{
                  item.activation.device_sign
                }}</span>
              </div>
              <div>
                Locked To:
                <span
                  v-if="
                    item.activation &&
                      isActivationLocked(item.license.activation_lock_to)
                  "
                  >{{ formatDate(item.license.activation_lock_to) }}</span
                >
              </div>
            </div>
            <div class="border p-2 my-2">
              <div>
                Payee:
                <span
                  v-if="item.paypal_payment"
                  v-bind:class="{
                    'text-warning':
                      item.paypal_payment.payee_email != 'niedelian@gmail.com',
                  }"
                  >&lt;{{ item.paypal_payment.payee_email }}&gt;</span
                >
              </div>
              <div>
                Amount:
                <span v-if="item.paypal_payment"
                  >{{ item.paypal_payment.amount / 100 }}
                  {{ item.paypal_payment.currency }}</span
                >
                <span v-if="item.paypal_payment"
                  >(Received:
                  {{
                    (item.paypal_payment.amount -
                      item.paypal_payment.paypal_fee) /
                      100
                  }}
                  Fee: {{ item.paypal_payment.paypal_fee / 100 }})</span
                >
              </div>
              <div>
                Paid Time:
                <span v-if="item.paypal_payment">
                  <strong>{{
                    formatDate(item.paypal_payment.create_time)
                  }}</strong>
                </span>
              </div>
              <div>
                User Email:
                <span>{{ item.license.user_email }}</span>
              </div>
              <div>
                Payer Email:
                <span v-if="item.paypal_payment"
                  >&lt;{{ item.paypal_payment.payer_email }}&gt;</span
                >
              </div>
              <div class="address-field" v-if="isSuperAdmin">
                Country:
                <span
                  v-if="
                    item.paypal_payment && item.paypal_payment.payer_country
                  "
                >
                  {{ item.paypal_payment.payer_country }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-5 pt-3">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          size="sm"
          class="float-xs-left float-sm-right my-0"
        ></b-pagination>
      </div>
    </div>

    <b-modal id="modal-set-payee-email" title="Set Payee Email" hide-footer>
      <form action method="post" enctype="multipart/form-data">
        <div class="form-group">
          <label>Payee Email:</label>
          <b-form-select
            class="form-control"
            v-model="payeeEmail"
            :options="payeeEmails"
            required
            v-validate="'required'"
          ></b-form-select>
          <div class="invalid-feedback">{{ errors.first("payeeEmail") }}</div>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          v-on:click.stop.prevent="setPayeeEmail()"
        >
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import * as config from "../../config";
import dayjs from "dayjs";
import axios from "axios";
import { EventBus } from "../event-bus.js";
import { mapGetters } from "vuex";

import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default {
  name: "LicenseList",
  data() {
    return {
      items: [],
      total: 0,
      perPage: 50,
      currentPage: 1,
      approved: "all",
      searchText: null,
      payeeEmail: null,
      payeeEmails: null,
      setPayeeEmailLicenseId: null,
      select: false,
      selectedLicenses: [],
    };
  },
  created() {
    let self = this;

    this.load();
    this.loadGlobalSettings();

    EventBus.$on("licenses-created", () => {
      self.load();
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoggedIn: "isLoggedIn",
    }),
    todaySales() {
      return !this.items
        ? 0
        : this.items.filter(
            (item) =>
              item.paypal_payment &&
              item.paypal_payment.status == "NORMAL" &&
              dayjs(item.paypal_payment.create_time).isSame(dayjs.utc(), "day")
          ).length;
    },
    isSuperAdmin() {
      return (this.user.roles & 0b10000000) > 0;
    },
  },
  watch: {
    currentPage: function() {
      if (this.searchText) {
        this.searchLicenses();
      } else {
        this.load();
      }
    },
  },
  methods: {
    selectAll(event) {
      if (event.target.checked) {
        this.selectedLicenses = this.items.map(
          (item) => item.license.license_key
        );
      } else {
        this.selectedLicenses = [];
      }
    },
    refresh() {
      EventBus.$emit("reload-daysales");
      this.searchText = null;
      this.load();
    },
    isOwner(script) {
      return +script.user.id === +this.user.id;
    },
    isTodaySale(createTime) {
      return createTime && dayjs(createTime).isSame(dayjs.utc(), "day");
    },
    formatDate(date) {
      if (!date) return null;
      const d = dayjs(date).format("YYYY-MM-DD HH:mm:ss Z");

      return d ? d : date;
    },
    routeTo(to) {
      this.$router.push(to);
    },
    isActivationLocked(activationLockTo) {
      if (!activationLockTo) return false;
      const activationLockToTime = new Date(activationLockTo);
      const utcNow = dayjs.utc().toDate();
      return utcNow < activationLockToTime;
    },
    load() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses`;

      axios
        .get(url, {
          params: {
            page: this.currentPage,
            limit: this.perPage,
          },
        })
        .then((resp) => {
          const { data } = resp;
          self.items = data.items;
          self.total = data.total;
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    searchLicenses() {
      const self = this;

      const url = `${config.server}/api/v1/licenses/search`;

      axios
        .get(url, {
          params: {
            search_text: self.searchText,
            page: this.currentPage,
            limit: this.perPage,
          },
        })
        .then(function(response) {
          const { items, total } = response.data;
          self.items = items;
          self.total = total;
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(function() {
          self.queried = true;
        });
    },
    unlockActivation(licenseId) {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses/${licenseId}/unlock_activation`;

      axios
        .put(url)
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    refund(licenseId) {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses/${licenseId}/refund`;

      axios
        .put(url)
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });

      return;
    },
    revoke(licenseId) {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses/${licenseId}/revoke`;

      axios
        .put(url)
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });

      return;
    },
    deletee(licenseId) {
      let self = this;

      if (!window.confirm("Are you sure to delete this license?")) {
        return;
      }

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses/${licenseId}`;

      axios
        .delete(url)
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    attachLicenseToEmail(licenseId) {
      let self = this;

      let email = prompt("Attach license to email:", "");
      if (!email) {
        return;
      }
      email = email.trim();

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses/${licenseId}/attach`;

      axios
        .put(url, { email })
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    setPayeeEmail() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses/${self.setPayeeEmailLicenseId}/payee_email`;

      axios
        .put(url, { payee_email: self.payeeEmail })
        .then(() => {
          self.$bvModal.hide("modal-set-payee-email");
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    renew() {
      let self = this;

      // Using prompt to get input from a user
      let input = prompt("How many months to extend:", "12");

      if (input == null) {
        return;
      }

      let months = parseInt(input, 10);

      if (months <= 0) {
        return;
      }

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/licenses/renew`;

      axios
        .post(url, {
          license_keys: self.selectedLicenses,
          validity_months_to_extend: months,
        })
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });

      return;
    },
    loadGlobalSettings() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/global_settings`;

      axios
        .get(url, {})
        .then((resp) => {
          const { paypal_payee_email_1, paypal_payee_email_2 } = resp.data;
          self.payeeEmails = [
            {
              value: null,
              text: `Null`,
            },
            {
              value: paypal_payee_email_1,
              text: `Payee Paypal Email 1 -- ${paypal_payee_email_1}`,
            },
            {
              value: paypal_payee_email_2,
              text: `Payee Paypal Email 2 -- ${paypal_payee_email_2}`,
            },
          ];
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.license-key {
  font-family: Monospace, "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.webhook-flag {
  background-image: url(../assets/webhook.svg);
  width: 16px;
  height: 16px;
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.card {
  border: 1px solid #e9e9e9;
  background-color: #ffffff;

  .card-body {
    padding: 10px;
  }
}

.toolbar {
  padding: 8px 15px;
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgb(228, 228, 228);
}

.card-today {
  box-shadow: 0 0 5px rgb(235, 47, 47);
}

.license-card {
  @media (max-width: 400px) {
    width: 100%;
  }

  @media (min-width: 700px) {
    width: 350px;
  }

  height: 100%;

  .basic-flag {
    color: #2060fe;
    text-shadow: 0 0 2px 0px #144cdb;
    font-size: 14px;
    font-weight: 600;
  }

  .pro-flag {
    color: #d6491a;
    text-shadow: 0 0 2px 0px #d6491a;
    font-size: 14px;
    font-weight: 600;
  }
}

.hand-flag {
  width: 12px;
  height: 12px;
}
</style>
