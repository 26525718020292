//格式化时间为00:00:00
function formatSeconds(value, format) {
    let result = parseInt(value);
    let zero = format ? "" : "0";
    let h =
        Math.floor(result / 3600) < 10
            ? zero + Math.floor(result / 3600)
            : Math.floor(result / 3600);
    let m =
        Math.floor((result / 60) % 60) < 10
            ? zero + Math.floor((result / 60) % 60)
            : Math.floor((result / 60) % 60);
    let s =
        Math.floor(result % 60) < 10
            ? zero + Math.floor(result % 60)
            : Math.floor(result % 60);

    if (format) {
        let res = "";
        if (h !== "0") res += `${h}hour`;
        if (m !== "0") res += `${m}min`;
        if (format == "hms") res += `${s}s`;
        return res;
    }

    let res = "";
    res += `${h}:`;
    res += `${m}:`;
    res += `${s}`;
    return res;
}

function formatSmall(num) {
    if (!num) return 0;
    var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
}

function getcurBalance(b, token_id, list) {
    if (!b) return 0;
    if (!token_id) return 0;
    if (!list) return 0;

    let target = list.find((item) => item.token_id == token_id);
    if (target) {
        b = b / Math.pow(10, target.decimals);
    } else {
        b = b / Math.pow(10, 0);
    }
    var m = b.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return b.toFixed(Math.max(0, (m[1] || "").length - m[2]));
}
// 对象格式查询字符串方法
function getSearchString() {
    // 获取URL中?之后的字符
    if (process.browser) {
        let str = window.location.search;
        str = str.substring(1, str.length);

        // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
        let arr = str.split("&");
        let obj = new Object();

        // 将每一个数组元素以=分隔并赋给obj对象
        for (let i = 0; i < arr.length; i++) {
            let tmp_arr = arr[i].split("=");
            obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
        }
        return obj;
    }
}

function randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

function humanFileSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

function getMonthAgo() {
    let d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
}

function getDaysAgo(days) {
    // Get the current date
    let date = new Date();
    // Subtract the desired number of days from the current date
    date.setDate(date.getDate() - days);
    return date;
}

function randomColorOnSeed(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
    }
    colour += "CC"; // alpha
    return colour;
}

export { formatSeconds, getSearchString, formatSmall, getcurBalance, randomString, humanFileSize, getMonthAgo, getDaysAgo, randomColorOnSeed };
