<template>
  <div id="app">
    <Navbar class="top mb-3" />
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-12 d-flex justify-content-center"
          v-if="
            globalSettings &&
            (!globalSettings.paypal_payee_email_1 ||
              !globalSettings.paypal_payee_email_2)
          "
        >
          <div class="alert alert-danger" role="alert">
            You have to set payee PayPal emails at global settings!
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <div v-if="showLoading" class="loading">
      <bounce-loader />
    </div>
    <notifications group="main" position="bottom right" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";
import { mapGetters } from "vuex";
import * as config from "../config";
import axios from "axios";

import { router } from "./router";

export default {
  name: "app",
  router,
  components: {
    Navbar,
    BounceLoader,
  },
  data() {
    return {
      globalSettings: null,
    };
  },
  created() {
    this.loadGlobalSettings();
  },
  computed: {
    ...mapGetters({
      showLoading: "getShowLoading",
    }),
  },
  methods: {
    loadGlobalSettings() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/global_settings`;

      axios
        .get(url, {})
        .then((resp) => {
          self.globalSettings = resp.data;
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 500px;
}
</style>
