export const auth = {
    state: {
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {id:''},
        loggedIn: localStorage.getItem('accessToken') != null,
    },
    mutations: {
        login(state) {
            state.loggedIn = true;
            state.user = JSON.parse(localStorage.getItem('user'));
        },
        logout(state) {
            state.loggedIn = false;
            state.user = {id:null};
        },
        PROFILE_RELOADED(state, newProfile) {
            state.user = newProfile;
            localStorage.setItem('user', JSON.stringify(newProfile));
        },
    },
    getters: {
        isLoggedIn: state => {
            return state.loggedIn;
        },
        getUser: state => {
            return state.user;
        }
    }
};