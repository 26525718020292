<template>
  <div>
    <div class="row py-3">
      <div class="col-12 d-flex justify-content-between">
        <h3>Users</h3>
        <div>
          <button
            class="btn btn-sm btn-secondary"
            @click.prevent.stop="$bvModal.show('modal-create-user')"
          >
            New User
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm table-bordered">
          <thead>
            <th>Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Roles</th>
            <th>Create Time</th>
            <th>Last Login</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="item in users" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.roles.join(", ") }}</td>
              <td>{{ formatDate(item.create_time) }}</td>
              <td>{{ formatDate(item.last_login_time) }}</td>
              <td>
                <b-dropdown
                  variant="button"
                  no-caret
                  right
                  v-if="isSuperAdmin(user.roles)"
                >
                  <template slot="button-content">
                    <b-icon icon="gear"></b-icon>
                  </template>

                  <b-dropdown-item
                    v-on:click.stop.prevent="
                      updateUsernameForm.id = item.id;
                      updateUsernameForm.username = item.username;
                      $bvModal.show('modal-update-username');
                    "
                    >Change Username</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-on:click.stop.prevent="
                      updateRolesForm.id = item.id;
                      updateRolesForm.roles = item.roles;
                      $bvModal.show('modal-update-roles');
                    "
                    >Change Roles</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click.stop.prevent="deleteUser(item.id)">
                    <span class="text-danger">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal id="modal-create-user" title="Pre Create User" hide-footer>
      <form action method="post">
        <div class="form-group">
          <label>Email:</label>
          <input
            type="email"
            class="form-control"
            name="email"
            v-model="preCreateUserForm.email"
            required
            v-validate="'required|email'"
          />
          <div class="invalid-feedback">{{ errors.first("email") }}</div>
        </div>
        <div class="form-group">
          <label>Roles:</label>
          <b-form-checkbox-group v-model="preCreateUserForm.roles" name="roles">
            <b-form-checkbox value="SUPER_ADMIN">SUPER ADMIN</b-form-checkbox>
            <b-form-checkbox value="AUDITOR">AUDITOR</b-form-checkbox>
            <b-form-checkbox value="USER">USER</b-form-checkbox>
          </b-form-checkbox-group>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          v-on:click.stop.prevent="preCreateUser()"
        >
          Submit
        </button>
      </form>
    </b-modal>

    <b-modal id="modal-update-roles" title="Update Roles" hide-footer>
      <form action method="post">
        <div class="form-group">
          <label>Roles:</label>
          <b-form-checkbox-group v-model="updateRolesForm.roles" name="roles">
            <b-form-checkbox value="SUPER_ADMIN">SUPER ADMIN</b-form-checkbox>
            <b-form-checkbox value="AUDITOR">AUDITOR</b-form-checkbox>
            <b-form-checkbox value="USER">USER</b-form-checkbox>
          </b-form-checkbox-group>
          <div class="invalid-feedback">{{ errors.first("roles") }}</div>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          v-on:click.stop.prevent="updateRoles()"
        >
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import * as config from "../../config";
import dayjs from "dayjs";
import axios from "axios";
import { mapGetters } from "vuex";
import { authService } from "../service/index";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

export default {
  name: "UserList",
  data() {
    return {
      users: [],
      total: 0,
      preCreateUserForm: {
        email: null,
        roles: [],
      },
      updateRolesForm: {
        id: null,
        roles: [],
      },
    };
  },
  created() {
    this.load();
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoggedIn: "isLoggedIn",
    }),
  },
  mounted() {
    let self = this;

    self.$root.$on("bv::modal::hide", () => {
      self.resetNewUserForm();
    });
  },
  methods: {
    isSuperAdmin(roles) {
      return (roles & 0b10000000) > 0;
    },
    isAuditor(roles) {
      return (roles & 0b00000010) > 0;
    },
    rolesToArray(roles) {
      let rs = [];
      if ((roles & 0b10000000) > 0) {
        rs.push("SUPER_ADMIN");
      }
      if ((roles & 0b00000010) > 0) {
        rs.push("AUDITOR");
      }
      if ((roles & 0b00000001) > 0) {
        rs.push("USER");
      }
      return rs;
    },
    arrayToRoles(arr) {
      let roles = 0b00000000;
      if (arr.includes("SUPER_ADMIN")) {
        roles &= 0b10000000;
      }
      if (arr.includes("AUDITOR")) {
        roles &= 0b00000010;
      }
      if (arr.includes("USER")) {
        roles &= 0b00000001;
      }
      return roles;
    },
    formatDate(date) {
      if (!date) return null;
      const d = dayjs(date).format("YYYY-MM-DD HH:mm:ss Z");

      return d ? d : date;
    },
    routeTo(to) {
      this.$router.push(to);
    },
    load() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/users`;

      axios
        .get(url, {})
        .then((resp) => {
          const { items, total } = resp.data;
          self.users = items;
          self.users.forEach((u) => {
            u.roles = this.rolesToArray(u.roles);
          });
          self.total = total;
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });

      return;
    },
    resetNewUserForm() {
      this.preCreateUserForm = {
        email: null,
        roles: 0,
      };
    },
    preCreateUser() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/users`;

      axios
        .post(url, self.preCreateUserForm)
        .then(() => {
          self.$bvModal.hide("modal-create-user");
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    updateUsername() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/users/${this.updateUsernameForm.id}/username`;

      axios
        .put(url, self.updateUsernameForm)
        .then(() => {
          if (self.updateUsernameForm.id == self.user.id) {
            authService.logout({
              group: "main",
              type: "success",
              text: "Re-login please!",
            });
          } else {
            self.$bvModal.hide("modal-update-username");
            self.load();
          }
          self.$notify({
            group: "main",
            type: "success",
            text: "Successful!",
          });
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    updateRoles() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/users/${this.updateRolesForm.id}/roles`;

      console.log(self.updateRolesForm.roles);

      axios
        .put(url, { roles: self.updateRolesForm.roles })
        .then(() => {
          if (self.updateRolesForm.id == self.user.id) {
            authService.logout();
          } else {
            self.$bvModal.hide("modal-update-roles");
            self.load();
          }
          self.$notify({
            group: "main",
            type: "success",
            text: "Successful!",
          });
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    deleteUser(userId) {
      let self = this;

      if (!window.confirm("Are you sure to delete this user?")) {
        return;
      }

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/users/${userId}`;

      axios
        .delete(url)
        .then(() => {
          self.$notify({ group: "main", type: "success", text: "Successful!" });
          self.load();
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
