import VueRouter from "vue-router";
import Overview from "./components/Overview.vue";
import Login from "./views/Login.vue";
import OrderList from "./components/OrderList.vue";
import UserList from "./components/UserList.vue";
import SalesStatistics from "./components/SalesStatistics.vue";
import UsingStatistics from "./components/UsingStatistics.vue";
import GlobalSettings from "./components/GlobalSettings.vue";
import Repo from "./views/Repo.vue";
import CrashLogs from "./views/CrashLogs.vue";

const isAuthenticated = () => localStorage.getItem("accessToken");

const authGuard = (to, from, next) => {
    if (to.name !== 'login' && !isAuthenticated()) next({ name: 'login' })
    else next()
};

const routes = [
    { path: "/", component: Overview, beforeEnter: authGuard },
    { path: "/login(/?):provider(.*)", name: "login", component: Login },
    { path: "/orders", component: OrderList, beforeEnter: authGuard  },
    { path: "/users", component: UserList, beforeEnter: authGuard  },
    { path: "/statistics/sales", component: SalesStatistics, beforeEnter: authGuard  },
    { path: "/statistics/using", component: UsingStatistics, beforeEnter: authGuard  },
    { path: "/globalSettings", component: GlobalSettings, beforeEnter: authGuard  },
    { path: "/repo", component: Repo, beforeEnter: authGuard  },
    { path: "/crashLogs", component: CrashLogs, beforeEnter: authGuard  },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export { router }