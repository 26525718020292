<template>
  <div class="row">
    <div class="col-12">
      <LicenseList class="mt-2" />
    </div>
  </div>
</template>

<script>
import LicenseList from "./LicenseList.vue";
import { mapGetters } from "vuex";

export default {
  name: "Overview",
  components: {
    LicenseList,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoggedIn: "isLoggedIn",
    }),
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
