<template>
  <div>
    <div class="row py-3">
      <div class="col-6 d-flex justify-content-start">
        <h3>Orders</h3>
        <div class="btn-group btn-group-sm ml-5" role="group">
          <button
            class="btn"
            :class="{
              'btn-light': renew,
              'btn-secondary': !renew,
            }"
            @click="renew = false"
            checked
          >
            New Orders
          </button>
          <button
            class="btn"
            :class="{
              'btn-light': !renew,
              'btn-secondary': renew,
            }"
            @click="renew = true"
          >
            Renew Orders
          </button>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          size="sm"
          class="float-xs-left float-sm-right my-0"
        ></b-pagination>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm table-bordered">
          <thead>
            <th>ID</th>
            <th v-if="!renew">Plan</th>
            <th v-if="!renew">Price</th>
            <th v-if="renew">Licenses</th>
            <th>Quantity</th>
            <th>Amount</th>
            <th v-if="!renew">Validity</th>
            <th v-else>Extend Validity</th>
            <th>Payment Method</th>
            <th>Payment</th>
            <th>Status</th>
            <th>Time</th>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.id }}</td>
              <td v-if="!renew">{{ item.plan }}</td>
              <td v-if="!renew">${{ item.price / 100 }}</td>
              <td v-if="renew">
                <div>
                  <div v-for="key in item.license_keys.slice(0, 5)" :key="key">
                    {{ key }}
                  </div>
                  <div v-if="item.license_keys.length > 5">...</div>
                </div>
              </td>
              <td v-if="!renew">{{ item.quantity }}</td>
              <td v-if="renew">{{ item.license_keys.length }}</td>
              <td>${{ item.amount / 100 }}</td>
              <td>{{ item.validity }} Months</td>
              <td>
                <div v-if="item.payment_method == 'PAYPAL'" class="flag-paypal">
                  <img src="../assets/paypal.png" alt="" />
                </div>
                <div
                  v-if="item.payment_method == 'OFTENPAY'"
                  class="flag-oftenpay"
                >
                  <img src="../assets/oftenpay.png" alt="" />
                </div>
              </td>
              <td>{{ item.status }}</td>
              <td>
                <div>{{ formatDate(item.create_time) }}</div>
                <div>{{ formatDate(item.update_time) }}</div>
              </td>
              <td>
                <div class="payment">
                  <div class="title">Amount:</div>
                  <div class="value">${{ item.payment.amount / 100 }}</div>
                  <div class="title">PayPal Fee:</div>
                  <div class="value">${{ item.payment.paypal_fee / 100 }}</div>
                  <div class="title">From Method:</div>
                  <div class="value">{{ item.payment.from_method }}</div>
                  <div class="title">Payee Email:</div>
                  <div class="value">{{ item.payment.payee_email }}</div>
                  <div class="title">Payer:</div>
                  <div class="value">{{item.payment.payer_email}}</div>
                  <div class="title">Country:</div>
                  <div class="value">{{ item.payment.payer_country }}</div>
                  <div class="title">Status:</div>
                  <div class="value">{{ item.payment.status }}</div>
                  <!-- <div class="title">Paid Time:</div>
                  <div class="value">{{ item.payment.paid_time }}</div> -->
                  <div class="title">Refund Time:</div>
                  <div class="value">
                    {{
                      item.payment.refund_time
                        ? formatDate(item.payment.refund_time)
                        : ""
                    }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as config from "../../config";
import dayjs from "dayjs";
import axios from "axios";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default {
  name: "OrderList",
  data() {
    return {
      items: [],
      total: 0,
      perPage: 50,
      currentPage: 1,
      renew: true,
    };
  },
  created() {
    this.loadOrders();
  },
  watch: {
    renew: function() {
      self.items = [];
      self.total = 0;

      this.loadOrders();
    },
    currentPage: function() {
      this.loadOrders();
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const d = dayjs(date).format("YYYY-MM-DD HH:mm:ss Z");

      return d ? d : date;
    },
    loadOrders() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      let url;
      if (self.renew) {
        url = `${config.server}/api/v1/renew_orders`;
      } else {
        url = `${config.server}/api/v1/orders`;
      }

      axios
        .get(url, {
          params: {
            page: this.currentPage,
            limit: this.perPage,
          },
        })
        .then((resp) => {
          const { data } = resp;
          self.items = data.items;
          self.total = data.total;
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.flag-paypal {
  color: #333;
  border-radius: 30px;
  background-color: #fec339;
  box-shadow: #cf9c23 1px 1px 1px;
  padding: 5px 7px;
  text-align: center;
  font-weight: bold;
  width: 100px;

  & > img {
    height: 18px;
  }
}
.flag-oftenpay {
  font-size: 1.1rem;
  width: 100px;
  color: #eee;
  border-radius: 30px;
  background: linear-gradient(172.83deg, #020664 1.44%, #c400bd 48.32%);
  box-shadow: #8c0387 1px 1px 1px;

  padding: 5px 7px;

  & > img {
    height: 18px;
  }
}

.payment {
  display: grid;
  grid-template-columns: 120px 300px 120px auto;
}
</style>
