<template>
  <div>
    <div class="row py-3">
      <div class="col-12 d-flex justify-content-between">
        <h3>Global Settings</h3>
        <div>
          <button
            class="btn btn-sm btn-secondary"
            @click.prevent.stop="$bvModal.show('modal-create-payee')"
          >
            New Payee
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="!!globalSettings">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th>PayPal Payee Email 1</th>
              <td>{{ globalSettings.paypal_payee_email_1 }}</td>
              <td>
                <b-dropdown variant="button" no-caret right>
                  <template slot="button-content">
                    <b-icon icon="gear"></b-icon>
                  </template>

                  <b-dropdown-item
                    v-on:click.stop.prevent="
                      setEmailIndex = 1;
                      setEmail = globalSettings.paypal_payee_email_1;
                      $bvModal.show('modal-set-payee-email');
                    "
                    >Set</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <th>PayPal Payee Email 2</th>
              <td>{{ globalSettings.paypal_payee_email_2 }}</td>
              <td>
                <b-dropdown variant="button" no-caret right>
                  <template slot="button-content">
                    <b-icon icon="gear"></b-icon>
                  </template>

                  <b-dropdown-item
                    v-on:click.stop.prevent="
                      setEmailIndex = 2;
                      setEmail = globalSettings.paypal_payee_email_2;
                      $bvModal.show('modal-set-payee-email');
                    "
                    >Set</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal
      id="modal-set-payee-email"
      :title="`Set Payee PayPal Email ${setEmailIndex}`"
      hide-footer
    >
      <form action method="post">
        <div class="form-group">
          <label>PayPal Email:</label>
          <input
            type="text"
            class="form-control"
            name="email"
            v-model="setEmail"
            required
            v-validate="'required|email'"
          />
          <div class="invalid-feedback">
            {{ errors.first("email") }}
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          v-on:click.stop.prevent="setPayeePaypalEmail()"
        >
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import * as config from "../../config";
import dayjs from "dayjs";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "GlobalSettings",
  data() {
    return {
      globalSettings: null,
      setEmailIndex: null, // 1 or 2
      setEmail: null,
    };
  },
  created() {
    this.load();
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoggedIn: "isLoggedIn",
    }),
  },
  mounted() {
    let self = this;

    self.$root.$on("bv::modal::hide", () => {
      self.resetNewPayeeForm();
    });
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const d = dayjs.unix(date).format("YYYY-MM-DD HH:mm:ss");
      return d;
    },
    routeTo(to) {
      this.$router.push(to);
    },
    load() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/global_settings`;

      axios
        .get(url, {})
        .then((resp) => {
          const { data } = resp;
          self.globalSettings = data;
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
    resetNewPayeeForm() {
      this.setEmailIndex = null;
      this.setEmail = null;
    },
    setPayeePaypalEmail() {
      let self = this;

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/global_settings/paypal_payee_email_${this.setEmailIndex}`;

      axios
        .put(url, {
          email: self.setEmail.trim(),
        })
        .then(() => {
          self.$bvModal.hide("modal-set-payee-email");
          self.load();
          self.$notify({
            group: "main",
            type: "success",
            text: "Successful!",
          });
        })
        .catch(function (error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
