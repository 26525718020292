<template>
  <div>
    <div class="row py-3">
      <div class="col-12">
        <div class="header-field">
          <div>
            <div class="d-flex justify-content-left align-items-center">
              <div class="">
                Path: <span class="badge badge-secondary">{{ this.path }}</span>
              </div>
              <button class="btn btn-sm btn-light ml-5" @click="backward">
                Back
              </button>
              <button class="btn btn-sm btn-light ml-2" @click="listFiles">
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <th>Filename</th>
            <th>Size</th>
            <th>Last Modified</th>
          </thead>
          <tbody>
            <tr v-for="item in files" :key="item.filename">
              <td
                class="d-flex flex-row justify-content-start align-items-center"
              >
                <input
                  type="checkbox"
                  class="mr-2"
                  v-if="managing"
                  :value="item.filename"
                  v-model="selectedItems"
                  @change="filesSelected"
                />
                <div
                  v-if="item.is_dir"
                  @click="enterDir(item.filename)"
                  class="field-filename"
                >
                  <b-icon icon="folder"></b-icon>
                  {{ item.filename }}
                </div>
                <div v-else class="field-filename">
                  <b-icon icon="file"></b-icon>
                  {{ item.filename }}
                </div>
              </td>
              <td>{{ humanFileSize(item.size) }}</td>
              <td>{{ formatDate(item.last_modified) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as config from "../../config";
import * as utils from "../utils/utils";
import { EventBus } from "../event-bus.js";
import dayjs from "dayjs";
import axios from "axios";
import path from "path";
import { mapGetters } from "vuex";

export default {
  name: "FileList",
  props: ["managing"],
  data() {
    return {
      path: ".",
      files: [],
      selectedItems: [],
    };
  },
  created() {
    let self = this;

    self.listFiles();

    EventBus.$on("reload-repo-files", () => {
      self.listFiles();
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoggedIn: "isLoggedIn",
    }),
  },
  methods: {
    humanFileSize(size) {
      if (!size) return null;
      return utils.humanFileSize(size);
    },
    formatDate(date) {
      if (!date) return null;
      const d = dayjs.unix(date).format("YYYY-MM-DD HH:mm:ss");
      return d;
    },
    routeTo(to) {
      this.$router.push(to);
    },
    backward() {
      if (this.path === ".") {
        return;
      }
      this.path = path.normalize(path.join(this.path, ".."));
      this.listFiles();
    },
    enterDir(dir) {
      this.path = path.join(path.normalize(this.path), dir);
      this.listFiles();
    },
    filesSelected() {
      let self = this;
      let selectedItems = self.selectedItems.map(
        (item) => `${self.path}/${item}`
      );
      this.$emit("filesSelected", selectedItems);
    },
    listFiles() {
      let self = this;

      self.selectedItems = [];

      self.$store.commit("setShowLoading", true);

      const url = `${config.server}/api/v1/repo/files`;

      axios
        .get(url, {
          params: {
            path: self.path,
          },
        })
        .then((resp) => {
          self.files = resp.data;
        })
        .catch(function(error) {
          self.$notify({ group: "main", type: "error", text: error });
        })
        .finally(() => {
          self.$store.commit("setShowLoading", false);
        });

      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  .field-filename {
    cursor: pointer;
  }
}

.header-field {
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  padding: 10px;
}
</style>
